<!-- src/App.vue -->
<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-row type="flex" justify="center">
          <el-col :span="18">
            <el-menu mode="horizontal" router default-active="/">
              <el-menu-item index="/">首页</el-menu-item>
              <el-menu-item index="/product">产品介绍</el-menu-item>
              <el-menu-item index="/team">团队介绍</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <p>
          Copyright © 2023-2025 广州六六柒柒科技服务有限公司：<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021015504号-3</a>
        </p>
    <!-- 友情链接的HTML示例 -->
    <!-- <p>
        友情链接：
        <a href="https://www.example1.com" target="_blank">链接1</a>, 
        <a href="https://www.example2.com" target="_blank">链接2</a>
    </p> -->
    </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-header,
.el-footer {
  background-color: #fff;
  text-align: center;
  padding: 20px;
}

.el-main {
  padding: 20px;
}

.el-footer p {
  margin: 0;
}
</style>